@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*:focus {
  outline: none;
  -webkit-tap-highlight-color: transparent;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  margin: 0;
  overflow: hidden;
  max-height: -webkit-fill-available;
}

/* crutches */

.h-screen {
  max-height: -webkit-fill-available;
}

/* theme color values */

.bg-dark {
  /* background-color: #171825; */
  background-color: #242424;
  /* background-color: #363636; */
}

.text-dark {
  /* color: #171825; */
  color: #242424;
}

.border-dark {
  /* color: #171825; */
  border-color: #242424;
}

.bg-gray {
  background-color: #3c3c3c;
}

.bg-fancy {
  background: rgba(251, 245, 255, 0.85);
  backdrop-filter: blur(12px);
}

.bg-gradient {
  background: linear-gradient(70deg, #00c2ff 13.21%, #9747ff 86.79%);
}

.bg-range-gradient {
  background: #242424
    linear-gradient(70deg, #00c2ff 13.21%, #9747ff 44.65%, #7de0ff 86.79%);
}

/* custom-styles */

.text-1rem {
  font-size: 1rem;
}

.text-1\.5rem {
  font-size: 1.5rem;
}

.text-2rem {
  font-size: 2rem;
}

.letter-spacing-005rem {
  letter-spacing: 0.05rem;
}

.border-gradient {
  border-image: linear-gradient(to right, #00c2ff, #7d48ff) 1;
}

.slider-track-0 {
  background-image: none !important;
  opacity: 0.15;
}

.slider-track-2 {
  background-image: none !important;
  opacity: 0.15;
}

.slider-shadow {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.3);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    /* transform: translateY(-2rem); */
  }
  to {
    opacity: 1;
    /* transform: translateY(0); */
  }
}

.animate-fade-in {
  animation: fadeIn 0.25s ease-in-out;
}
